import React from 'react';
import styled from 'styled-components';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

const ContactModal = ({ children, onClose }) => (
  <ModalBackground onClick={onClose}>
    <ModalContainer onClick={e => e.stopPropagation()}>
      {children}
      <CloseButton onClick={onClose}>Close</CloseButton>
    </ModalContainer>
  </ModalBackground>
);

export default ContactModal;
