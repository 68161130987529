import styled from 'styled-components';
import Image1 from './iStock-1418693545.jpg';
import CloudIcon from './cloudIcon.jpg';
import AIIcon from './AIIcon.jpg';
import WebIcon from './codeIcon.jpg';


const HomePageContainer = styled.div`
    padding: 60px 100px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    margin-bottom:120px;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 30px 20px;
           margin-bottom:190px;
    }
`;
const Section = styled.div`
    padding-bottom: 10px;

    h1, h2 {
        font-size: 32px;
        margin-bottom: 20px;
        color: #8ecf41;

        @media (max-width: 768px) {
            text-align: center;
        }
    }

    p {
        font-size: 18px;
        line-height: 1.6;
        color: #666;
        text-align: left;

        @media (max-width: 768px) {
            text-align: center;
        }
    }

    button {
        margin-top: 20px;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: #8ecf41;
        color: #ffffff;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #72b237;
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }

    li {
        margin: 10px 20px;
        font-size: 18px;
        color: #666;
    }
`;

const ContentContainer = styled.div`
    flex: 2;

    @media (max-width: 768px) {
        text-align: center;
    }
`;

//const VideoContainer = styled.div`
//    flex: 1;
//    video {
//        width: 100%;
//        border-radius: 8px;
//    }
//`;
//const AboutImage = styled.img`
//    flex: 1;  // Take up half the space
//    max-width: 40%;
//    border-radius: 8px; // Optional, to give the image rounded corners

//    @media (max-width: 768px) {  // Responsive adjustment for smaller screens
//        margin-top: 30px;
//        max-width: 80%;  // Make the image a bit larger on mobile, so it takes more space
//    }
//`;
const MethodologySection = styled(Section)`
    background-color: #f5f5f5;  // Light gray
    padding: 20px 0;  // Adding some padding for aesthetics
    border-radius: 8px;  // Optional rounded corners

    @media (max-width: 768px) {
        padding: 15px 0;
    }
`;

const HeaderSection = styled.div`
    h1, h2 {
        font-size: 32px;
        margin-bottom: 20px;
        color: #8ecf41;

        @media (max-width: 768px) {
            text-align: center;
        }
    }

    p {
        font-size: 18px;
        line-height: 1.6;
        color: #666;
        text-align: left;

        @media (max-width: 768px) {
            text-align: center;
        }
    }
    .flexContainer {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
`;

const AboutImage = styled.img`
    width: 70%;  // Allow the image to take up the full width of its container
    border-radius: 12px;
    margin-left:280px; 
    margin-top:-12px;
  
    @media (max-width: 768px) {  
        margin-left: 0px;
            }
`;

const StyledLink = styled.a`
    margin-top: 20px;
    display: inline-block;
    padding: 10px 20px;
    background-color: #8ecf41;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    text-align: center;
    text-decoration: none; // to remove underline from anchor
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #72b237;
    }
`;
//const ImagesContainer = styled.div`
//    display: grid;
//    grid-template-columns: repeat(1, 1fr); // Three images per row
//    gap: 100px; // Spacing between images
//    max-width: 100%; // Ensuring it doesn't overflow
//    margin-left:20px;

//    img {
//        width: 100%; // Images take full width of their container
//        border-radius: 8px; // Rounded corners for images
//    }

//    img:first-child {  // Target the first image
//        margin-top: 20px;  // Add margin to the top
//    }

//    @media (max-width: 768px) {
//        grid-template-columns: repeat(2, 1fr); // Two images per row for mobile screens
//        gap: 10px; // Spacing between images
//           margin-left:0px;

//        img:first-child {  // Target the first image in mobile view
//              margin-top: 0px;  // Add margin to the top
//        }
//    }
//`;



const Icon = styled.img`
    width: 40px;  // adjust this as necessary
    height: 40px; // adjust this as necessary
    margin-right: 10px; // space between icon and text
    vertical-align: middle; // to align with the text
`;

//... [rest of the styled components and content are similar to before]

function HomePage() {
    return (
        <HomePageContainer>
            <ContentContainer>
                <HeaderSection>
                    <h1>Transforming Businesses in the Digital Era</h1>
                    <p>At Zumosoft, we don't just leverage technology; we redefine its boundaries. With a passionate team of experts, we deep dive into your unique challenges, craft tailor-made solutions, and empower your business to thrive in an ever-evolving digital landscape. We're not just technologists; we're your partners in growth and innovation.</p>
                          <AboutImage src={Image1} alt="Placeholder" />
                     </HeaderSection>

                   <Section>
                    <h2>Our Vision & Mission</h2>
                    <p>At Zumosoft, we believe in the transformative power of technology. But what truly sets us apart is our unwavering commitment to excellence, a client-centric approach, and the unique blend of expertise and passion we bring to every project. Our mission isn't just to bridge the gap between businesses and technology; it's to be the architects of your digital future, ensuring sustainable growth and innovation in a rapidly evolving ecosystem.</p>
                </Section>
                <Section>
                    <h2>Our Services</h2>
                    <p>From cloud solutions to AI-driven insights, our vast tech stack is tailored to fit your needs:</p>
                    <ul>
                        {/* Add icons with brief descriptions or bullet points highlighting key technologies */}
                        <li><Icon src={CloudIcon} alt="Cloud Computing Icon" />Cloud Computing</li>
                        <li><Icon src={AIIcon} alt="AI & Machine Learning Icon" />AI & Machine Learning</li>
                        <li><Icon src={WebIcon} alt="Web & Mobile Development Icon" />Web & Mobile Development</li>
                        {/* ...more items */}
                    </ul>
                </Section>
                <MethodologySection>
                    <h2>Our Methodology</h2>
                    <p>Success in the digital realm is not by accident&mdash;it's by design. At Zumosoft, our refined methodology ensures that every project is executed with precision. From initial consultation to final deployment, we champion a collaborative and iterative approach, always keeping your business objectives at the core.</p>
                </MethodologySection>

                <Section>
                    <h2>Value-Driven Approach</h2>
                    <p>Every dollar you invest with us is a step towards your business's future. We pride ourselves on being transparent, efficient, and effective&mdash;eliminating wastage and ensuring that every project delivers maximum value. Partner with Zumosoft, where your budget translates into real, tangible results.</p>
                </Section>
                <Section>
                    <h2>Why Wait to Shape Tomorrow?</h2>
                    <p>The digital landscape is ever-evolving. Those who adapt and innovate stand out; those who delay risk being left behind. At Zumosoft, we ensure that your business isn't just keeping up&mdash;it's leading the charge. Harness the power of cutting-edge technology and become a pioneer in your industry. Are you ready to take the next step into the future?</p>
                    <StyledLink
                         href="/contact"
                        alt="contact us"
                    >
                        Let's Begin Your Journey
                    </StyledLink>

                  </Section>
            </ContentContainer>

           

        </HomePageContainer>
    );
}


export default HomePage;
