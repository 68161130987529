import styled from 'styled-components';
import logo from '../Zumosoft.png';
import React, { useState } from 'react';

const NavContainer = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px 5px 0px;
    background: #0b0d21;
    z-index: 10;

    a {
        color: white;
        text-decoration: none;
          }

    img {
        width: 200px;
        transition: width 0.3s ease;
    }

`;

const NavList = styled.ul`
    display: flex;
    gap: 20px;
    list-style-type: none;

    @media (max-width: 768px) { 
        display: none; // Hide NavList on mobile screens
    }
`;

const NavItem = styled.li`
 padding: 0px 13px 0px 10px; 
    @media (max-width: 768px) {
        margin: 5px 0;
    }
`;
const Hamburger = styled.div`
    width: 30px;
    height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    div {
        width: 100%;
        height: 4px;
        background: white;
        border-radius: 2px;
    }

    @media (min-width: 769px) {
        display: none; // Hide hamburger icon on larger screens
    }
`;

const MobileMenu = styled.div`
    display: ${props => (props.open ? 'block' : 'none')};
    position: absolute;
    top: 60px; // adjust based on the height of your navbar
    left: 0; // position it on the left side
    background: #0b0d21;
    border-radius: 8px;
    overflow: hidden;
    width: 250px; // adjust width based on your design

    a {
        display: block;
        padding: 10px 20px;
        color: white;
        text-decoration: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        &:last-child {
            border-bottom: none;
        }
    }

    @media (min-width: 769px) {
        display: none; // Hide mobile menu on larger screens
    }
`;


function Nav() {
      const [menuOpen, setMenuOpen] = useState(false);
    return (
        <NavContainer>
            <Hamburger onClick={() => setMenuOpen(!menuOpen)}>
                <div></div>
                <div></div>
                <div></div>
            </Hamburger>
            <MobileMenu open={menuOpen}>
                <a href="/services" onClick={() => setMenuOpen(false)}>Services</a>
                <a href="/clients" onClick={() => setMenuOpen(false)}>Clients</a>
                <a href="/about" onClick={() => setMenuOpen(false)}>About</a>
                {/* ... other nav items */}
            </MobileMenu>
            <a href="/"><img src={logo} alt="Zumosoft Logo" /></a>
            <NavList>
                <NavItem><a href="/services">Services</a></NavItem>
                <NavItem><a href="/clients">Clients</a></NavItem>
                <NavItem><a href="/about">About</a></NavItem>
                <NavItem><a href="/environment">Environmental Commitment</a></NavItem>
                    {/* ... other nav items */}
            </NavList>             
        </NavContainer>
    );
}

export default Nav;
