import styled from 'styled-components';

const ServicesContainer = styled.div`
    padding: 60px 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #f6f6f6;
    overflow-y: auto;
    margin-bottom:20px;

    @media (max-width: 768px) {
        padding: 60px 10px;
        margin-bottom:140px;
    }
`;

const ServiceItem = styled.div`
    flex: 1;
    margin: 20px; 
    padding: 30px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

    h2 {
        font-size: 18px;
        margin-bottom: 10px;
        color: #333333; 
    }

    p {
        font-size: 18px;
        color: #666;
    }

    @media (max-width: 768px) {
        flex-basis: 100%;
    }
`;

function Services() {
    return (
        <ServicesContainer>
            <ServiceItem>
                <h2>AI & Machine Learning</h2>
                <p>Leveraging the latest AI technologies to provide predictive analytics, automation, and other state-of-the-art solutions to drive businesses into the future.</p>
            </ServiceItem>
            <ServiceItem>
                <h2>React & React Native Development</h2>
                <p>Specializing in creating responsive web and native mobile applications with React and React Native, delivering user-centric designs and seamless user experiences.</p>
            </ServiceItem>
            <ServiceItem>
                <h2>Azure & O365 Solutions</h2>
                <p>Unlock the potential of Microsoft's cloud with Azure, and empower collaboration and productivity with Office 365 solutions tailored to your organization.</p>
            </ServiceItem>
            <ServiceItem>
                <h2>SharePoint Customization</h2>
                <p>Enhance collaboration and workflow automation with SharePoint, from out-of-the-box solutions to custom integrations that fit your business needs.</p>
            </ServiceItem>
            <ServiceItem>
                <h2>Advanced Search with Algolia & Solr</h2>
                <p>Offering advanced search capabilities with Algolia and Solr integration, ensuring rapid, accurate data discoverability for your users.</p>
            </ServiceItem>
            <ServiceItem>
                <h2>End-to-End Full Stack Development</h2>
                <p>Expertise in Razor, C#, JavaScript, and more, delivering comprehensive solutions from backend logic to interactive frontend designs.</p>
            </ServiceItem>
            <ServiceItem>
                <h2>Enterprise Solutions</h2>
                <p>Scalable solutions for SMEs and larger enterprises, collaborating with a network of skilled professionals for larger projects to deliver unparalleled results.</p>
            </ServiceItem>
        </ServicesContainer>
    );
}

export default Services;

