import styled from 'styled-components';

const EnvironmentContainer = styled.div`
    padding: 40px 80px;
    background-color: #e8f5e9; // A soft green shade for an environmental feel
    border-radius: 8px;
    margin: 20px 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

    h2 {
        font-size: 24px;
        color: #2e7d32; // A deeper green for contrast
        margin-bottom: 15px;
    }

    p {
        font-size: 18px;
        color: #4caf50; // Matching the green theme
        line-height: 1.5;
    }

    @media (max-width: 768px) {
        padding: 40px 20px;
    }
`;

function GreenStatement() {
    return (
        <EnvironmentContainer>
            <h2>Our Commitment to the Environment</h2>
            <p>
                In a world where the term "Greenwashing" has been coined due to insincere environmental pledges, we understand the gravity of making genuine commitments. As we evolve, our goal is to develop an environmental strategy that is both impactful and authentic. When we announce our stance, know that it will reflect our true intentions and diligent efforts to foster a sustainable future.
            </p>
        </EnvironmentContainer>
    );
}

export default GreenStatement;
