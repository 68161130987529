import React from 'react';
import styled from 'styled-components';
import PALSLogo from './PALS_logo.png';
import MedExplainLogo from './MedExplain_logo.png';

const ClientsContainer = styled.div`
   padding: 60px 120px 120px 120px;
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   background: #ffffff;
   overflow-y: auto;

   @media (max-width: 768px) {
       flex-direction: column-reverse;
       align-items: center;
       padding: 30px 20px;
       margin-bottom: 195px;
   }

   margin-bottom: 50px; // Added margin-bottom for spacing
`;

const ClientInner = styled.div`
   display: flex;
   justify-content: space-around; // Distribute space evenly between items
   align-items: center;
   width: 100%; // Full width to ensure it covers the container

   @media (max-width: 768px) {
       flex-direction: column;
       align-items: center;
   }
`;

const ClientItem = styled.div`
   border: 2px solid #e0e0e0;
   border-radius: 8px;
   padding: 2px;
   display: flex;
   align-items: center;
   justify-content: center;
   transition: transform 0.3s, box-shadow 0.3s;
   width: 320px;
   height: 180px;
   margin: 20px;

   &:hover {
       transform: translateY(-5px);
       box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
   }

   img {
       max-width: 90%;
   }

   &:nth-child(2) {
       font-size: 42px;
       font-weight: bold;
       color: #800020;
   }

   @media only screen and (max-width: 768px) {
       margin-top: 15px;
       width: 80%;
       padding: 8px;
       width: 320px;
       height: 120px;
       max-height: calc(100vh - 200px);  // Use max-height here instead of height

       &:nth-child(2) {
           font-size: 28px;
           padding-top: 20px;
           padding-bottom: 20px;
       }

       img {
           max-width: 60%;
       }
   }
`;

function Clients() {
    return (
        <ClientsContainer>
            <ClientInner>
                <ClientItem>
                    <a href="https://www.medexplain.org" target="_blank" rel="noopener noreferrer">
                        <img src={MedExplainLogo} alt="MedExplain Logo" />
                    </a>
                </ClientItem>
                <ClientItem>Weill Cornell</ClientItem>
                <ClientItem>
                    <a href="https://palsforhealth.com" target="_blank" rel="noopener noreferrer">
                        <img src={PALSLogo} alt="PALS Logo" />
                    </a>
                </ClientItem>
            </ClientInner>
        </ClientsContainer>
    );
}

export default Clients;
