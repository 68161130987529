import styled from 'styled-components';
import MyPhoto from './AnspaughHeadshot2.png';  // Import your photo

const AboutContainer = styled.div`
    padding: 60px 100px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: #ffffff; 
    overflow-y: auto;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
        padding: 30px 20px;
        margin-bottom:195px;
    }

    margin-bottom: 50px; // Added margin-bottom for spacing
`;

const AboutText = styled.div`
    flex: 1;  // Take up half the space (if there are two elements)

    h2 {
        font-size: 32px;
        margin-bottom: 20px;
        color: #8ecf41;

         @media (max-width: 768px) {  // Center the text on mobile view
            text-align: center;
        }
    }

    p {
        font-size: 18px;
        line-height: 1.6;
        color: #666;
        text-align: left;  // By default, text is aligned to the left

        @media (max-width: 768px) {  // Center the text on mobile view
            text-align: center;
        }
    }
    button {
        margin-top: 20px;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: #8ecf41;
        color: #ffffff;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #72b237;
        }
    }
`;
const StyledLink = styled.a`
    margin-top: 20px;
    display: inline-block;
    padding: 10px 20px;
    background-color: #8ecf41;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    text-align: center;
    text-decoration: none; // to remove underline from anchor
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #72b237;
    }

    // This media query will center the link on screens up to 768px wide (typical breakpoint for tablets and smaller devices).
    @media (max-width: 768px) {
        display: block; // make it block-level
        margin-left: auto;
        margin-right: auto;
    }
`;

const AboutImage = styled.img`
    flex: 1;  // Take up half the space
    max-width: 30%;
    border-radius: 8px; // Optional, to give the image rounded corners

    @media (max-width: 768px) {  // Responsive adjustment for smaller screens
        margin-top: 30px;
        max-width: 80%;  // Make the image a bit larger on mobile, so it takes more space
    }
`;

function About() {
    return (
        <AboutContainer>  
            <AboutText>
                <h2>About the Founder</h2>  {/* Updated the title */}
                <p>
                    Welcome! I'm Susan M. Anspaugh, MBA, the driving force behind Zumosoft. With over two decades immersed in the IT world, I've transitioned from hands-on technical engagements to visionary leadership roles. Founded in January 2021, Zumosoft was born out of a singular vision: to bridge the divide between businesses and the transformative power of technology. My experience has instilled a deep-seated belief in technology's potential to elevate businesses. At Zumosoft, we do more than just deliver tech solutions; we craft enduring collaborations. Committed to the principles of innovation and inclusivity, our team aims to usher businesses into a future filled with untapped opportunities.
                </p>
                <StyledLink
                    href="/contact"
                    alt="contact us"
                >
                    Get Started with Zumosoft
                </StyledLink>
                </AboutText>
            <AboutImage src={MyPhoto} alt="Susan M. Anspaugh" />
           </AboutContainer>
    );
}

export default About;
