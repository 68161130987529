import React, { useState } from 'react';
import './54321.css'; // Assume you have some basic CSS for styling

const Five = () => {
    const [tasks, setTasks] = useState({
        redLightTherapy: false,
        exercise: false,
        berberine: false,
        greenTea: false,
        vegetableIntake: false,
    });

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setTasks((prevTasks) => ({
            ...prevTasks,
            [name]: checked,
        }));
    };

    return (
        <div className="app">
            <h1>Health Checklist</h1>
            <div className="task-list">
                <div className="task-item">
                    <input
                        type="checkbox"
                        name="redLightTherapy"
                        checked={tasks.redLightTherapy}
                        onChange={handleCheckboxChange}
                    />
                    <label>Red Light Therapy</label>
                </div>
                <div className="task-item">
                    <input
                        type="checkbox"
                        name="exercise"
                        checked={tasks.exercise}
                        onChange={handleCheckboxChange}
                    />
                    <label>Exercise</label>
                </div>
                <div className="task-item">
                    <input
                        type="checkbox"
                        name="berberine"
                        checked={tasks.berberine}
                        onChange={handleCheckboxChange}
                    />
                    <label>Berberine 30 mins before meals</label>
                </div>
                <div className="task-item">
                    <input
                        type="checkbox"
                        name="greenTea"
                        checked={tasks.greenTea}
                        onChange={handleCheckboxChange}
                    />
                    <label>Green Tea</label>
                </div>
                <div className="task-item">
                    <input
                        type="checkbox"
                        name="vegetableIntake"
                        checked={tasks.vegetableIntake}
                        onChange={handleCheckboxChange}
                    />
                    <label>Vegetable Intake</label>
                </div>
            </div>
        </div>
    );
};

export default Five;
