import React, { useState } from 'react';
import styled from 'styled-components';
import ContactModal from './ContactModal';

const ContactFormContainer = styled.div`
    max-width: 800px; /* Increased from 600px to 800px */
    margin: 0 auto 90px auto;
     text-align: center;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      overflow-y: auto;

    @media (max-width: 768px) {
        /*padding: 60px 10px;*/
        margin-bottom:220px;
           }
`;

const Form = styled.form`
    margin-top: 20px;
`;

const FormGroup = styled.div`
    margin-bottom: 20px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px 15px; /* Increased padding on the left and right */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s ease;
    box-sizing: border-box; /* Added box-sizing */

    &:focus {
        border-color: #007BFF;
        outline: none;
    }
`;

const TextArea = styled.textarea`
    width: 100%;
    padding: 10px 15px; /* Increased padding on the left and right */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s ease;
    box-sizing: border-box; /* Added box-sizing */

    &:focus {
        border-color: #007BFF;
        outline: none;
    }
`;

const Button = styled.button`
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
     @media (max-width: 768px) {
       align:center;
    }
`;

const ResponseMessage = styled.p`
    margin-top: 20px;
    color: ${props => props.success ? 'green' : 'red'};
    font-weight: 600;
    text-align: center;
`;

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
    });
      const handleCloseModal = () => {
    setResponseMessage(null);
  };
    const [responseMessage, setResponseMessage] = useState(null);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const apiUrl = process.env.REACT_APP_API_URL;

        // Basic form validation
        if (!formData.name || !formData.email || !formData.message) {
            setResponseMessage({ type: 'error', text: 'Please fill out all required fields.' });
            return;
        }

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setResponseMessage({ type: 'success', text: 'Your message has been sent successfully! Thank you for reaching out to us. We will be in touch soon.' });
                // Clear form data here if necessary
            } else {
                const result = await response.text();
                setResponseMessage({ type: 'error', text: `Error submitting the form: ${result}` });
            }
        } catch (error) {
            setResponseMessage({ type: 'error', text: `Error submitting the form: ${error.message}` });
            console.error("Error submitting the form:", error);
        }
    };

    return (
        <ContactFormContainer>
            <h2>We'd Love to Hear From You</h2>
            <p>
                At Zumosoft, we're at the forefront of AI & Machine Learning, React & React Native Development, and O365 projects. Backed by a team of passionate experts and a legacy of excellence, we're committed to your success. Whether you're seeking answers, wish to provide feedback, or simply want to engage with us &mdash; we're just a message away.
            </p>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label htmlFor="name">Name:</Label>
                    <Input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Your full name"
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="email">Email:</Label>
                    <Input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="example@domain.com"
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="phone">Phone:</Label>
                    <Input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="123-456-7890"
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="message">Tell Us About Your Project or Inquiry:</Label>
                    <TextArea
                        id="message"
                        name="message"
                        rows="4"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Describe your project, ask a question, or share feedback..."
                        required
                    ></TextArea>
                </FormGroup>
                <Button type="submit">Submit</Button>
            </Form>
     {responseMessage && (
  <ContactModal onClose={handleCloseModal}>
    <ResponseMessage success={responseMessage.type === 'success'}>
      {responseMessage.text}
    </ResponseMessage>
  </ContactModal>
)}
        </ContactFormContainer>
    );
};

export default ContactForm;