import React from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
/*import logo from './Zumosoft.png';*/
import Services from './components/Services';
import Clients from './components/Clients';
import Nav from './components/Nav';
import About from './components/About';
import HomePage from './components/Homepage';
import ContactForm from './components/Contact';
import GreenStatement from './components/Green';
import Five from './components/54321'

const PageWrapper = styled.div`
  background: linear-gradient(135deg, #6d5bba, #48a9a6);
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Arial', sans-serif;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;  // To ensure no overflow from child elements
  color: white;
`;

const Header = styled.header`
  background: #0b0d21;
  padding: 20px 20px; /* Increased the padding to give more height */
  display: flex;
  align-items: center;
  color: white;

  img {
    width: 260px; /* Increased the logo size */
    margin-right: 20px;
  }

  h1 {
    margin: 0;
  }

  @media only screen and (max-width: 768px) {
    padding: 15px 10px; 

    img {
      width: 200px;
      margin-right: 10px;
    }
  }
`;

//const MainContent = styled.main`
 // overflow-y: auto;  // Make this scrollable
 // max-height: calc(100vh - 60px - 60px);  // Assuming header is 60px and footer is 60px
//  width: 100%;  // To ensure it takes full width
//  padding: 0 20px; // Optional padding
//`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  a {
    margin: 0 10px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    a {
      margin: 5px 0;
    }
  }
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center; // Change to center
  align-items: center;
  flex-wrap: wrap;
  background: #0b0d21;
  padding: 20px 10px; 
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;

    a {
    color: white;
    padding: 0px 16px; 
    text-decoration:none;
      }
     p {
    color: white;
    padding: 0px 16px; 
   /* font-weight:bold;*/
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

function App() {
    const currentYear = new Date().getFullYear();
    return (
                 <Router>
            <PageWrapper>
                <Header>
                    <Nav />
                </Header>
                <Container>
                  <Routes>
    <Route path="/services" element={<Services />} />
    <Route path="/clients" element={<Clients />} />
     <Route path="/about" element={<About />} />
      <Route path="/" element={<HomePage />} />
      <Route path="/contact" element={<ContactForm />} />
                        <Route path="/environment" element={<GreenStatement />} />
                        <Route path="/54321" element={<Five />} />
     {/*  <Route path="/partnerships" element={<Partnerships />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/news-updates" element={<NewsUpdates />} />
    <Route path="/" element={<Homepage />} />  */}
</Routes>
                   </Container>
                <Footer>
                    <FooterLinks>
                        <Link to="/services">Services</Link>
                        <Link to="/clients">Clients</Link>
                        <p>&copy; {currentYear} Zumosoft, Inc.</p>
                        <Link to="/about">About Us</Link>
                        <Link to="/environment">Environmental Commitment</Link>
                       {/* <Link to="/contact">Contact Us</Link>*/}
                    </FooterLinks>                   
                </Footer>
            </PageWrapper>
        </Router>
       );
}

export default App;


